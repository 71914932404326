import './table.css';
import React from 'react';

const Table = (props)=>{

    return (<table className="table">
        <tr>
        {
            props.header.map((v, i)=>{
                return <th>{v}</th>;
            })
        }
        </tr>
        {props.children}
    </table>)
}

export {
    Table
}