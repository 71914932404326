import {useEffect, useState, useContext} from 'react';
import React from 'react';
import {Link} from 'react-router-dom';
import AuthContext from 'auth/context';

const Header = (props)=>{
    const {auth, currentUser} = useContext(AuthContext);

    function logout(){
        auth.logout();
    }

    return (
    <div className="frame__header">
        {
            currentUser &&
            <div>
                <div className="frame__header__username">{currentUser.username}</div>
                <span className="frame__header__logout" onClick={logout}>Logout</span>
            </div> ||
            <Link className="frame__header__login" to='/login'>
                <button>Login</button>
            </Link>
        }
    </div>
    )
}

export {
    Header
}