import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import {Routes} from 'router/index';
import AuthContext from 'auth/context';
import Notifications from 'react-notify-toast';
import {authService} from 'auth';

function App() {
  let [currentUser, setUser] = useState(authService._initUser);

  const authContext = {auth: authService, currentUser}

  useEffect(
    ()=>{
      authService.userSubject.subscribe(
        (value)=>{
          setUser(value);
        }
      )
    }
  ,[])

  return (
    <React.Fragment>
      <Notifications />
      <AuthContext.Provider value={authContext}>
        <Router>
        <div className="App">
          <Routes />
        </div>
        </Router>
      </AuthContext.Provider>
    </React.Fragment>
  );
}

export default App;
