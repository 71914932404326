import React, {useState, useEffect} from 'react';
import FormContext from './context';
import TextInput from './text';
import CheckboxInput from './checkbox';
import './form.css';
import {setKey} from './path';

const Form = ({callback, ...props})=>{
    let [obj, setObj] = useState(props.initial);
    let className = props.unset && 'form--unset ' + props.className  || 'form ' + props.className;
    let [skip, setSkip] = useState(false)

    const updateValue = (key, value)=>{
        let tempObj = {...obj};
        setKey(tempObj, key, value);
        setObj(tempObj);
    }

    useEffect(
        ()=>{
            callback(obj);
        },
        [obj]
    )

    return (
        <FormContext.Provider value={{obj, updateValue}}>
            <div className={className} style={props.style}>
                {props.children}
            </div>
        </FormContext.Provider>
    )
}

export {
    Form,
    TextInput,
    CheckboxInput
}