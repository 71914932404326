import React from 'react';
import './card.css';

const DashCard = ({title, value})=>{

    return <div className="dash__card">
        <span className="dash__key">{title}</span>
        <h2 className="dash__value">{value}</h2>
    </div>
}

export {
    DashCard
}