import React, {useEffect, useState, useContext} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import RouteContext from 'router/context';
import {BreadcrumbBack} from 'components/general/navigation';
import {Heading} from 'components/general/heading';
import {Form, TextInput} from 'components/general/form';
import {alert} from 'alert';
import {API_URL} from 'config';

const ManageAgent = ()=>{
    let {auth} = useContext(AuthContext);
    let {match} = useContext(RouteContext);
    let [agent, setAgent] = useState(false);
    let [newAgent, setNewAgent] = useState(false);
    let [loading, setLoading] = useState(true);
    let [saving, setSaving] = useState(false);

    const getAgent = async ()=>{
        let res = await auth.ajax(API_URL+'/v1/agents/'+match.params.agent);
        if(res.ok){
            if(res.agents.length){
                let agent = res.agents[0];
                setNewAgent(agent);
                setAgent(agent);
            }
        }
        return setLoading(false);
    }

    useEffect(()=>{
        getAgent();
    }, []);

    const updateAgent = async ()=>{
        setSaving(true);
        let newagent = await auth.ajax(API_URL+'/v1/agents/update', 
            {
                method: 'POST', 
                body: JSON.stringify(newAgent)
            }
        );
        setSaving(false);
        if(!newagent.ok){
            return alert.error(newagent.error);
        }
        if(!newagent.data.length){
            return alert.error('some error');
        }
        setAgent(newagent.data[0]);
        alert.success('Agent updated successfully', 'success');
    }

    let isChanged = JSON.stringify(agent) == JSON.stringify(newAgent);

    return <React.Fragment>
        <BreadcrumbBack href="/agents" text="Back to all agents" />
        {
            loading &&
            <Heading loading={loading} text="Loading..."/> ||
            (!agent && 
            <React.Fragment>
                <Heading text={`Agent could not be found.`}/>
            </React.Fragment> ||
            <React.Fragment>
                <Heading loading={saving} text={`Manage agent: ${agent && agent.username}`}/>
                <Form initial={newAgent} callback={setNewAgent}>
                    <TextInput autocomplete="off" disabled label="ID" objKey="id" />
                    <TextInput autocomplete="off" label="username" objKey="username" />
                    <TextInput autocomplete="off" label="e-mail" objKey="email" />
                    <Button inProgress={{state: saving, text: 'Updating agent...'}} disabled={isChanged} onClick={updateAgent} type="primary">Update agent</Button>
                </Form>
            </React.Fragment>
            )
        }
        {

        }
        
    </React.Fragment>
}

export {
    ManageAgent
}