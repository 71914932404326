import React, {useState, useContext} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import {BreadcrumbBack} from 'components/general/navigation';
import {Heading} from 'components/general/heading';
import {Form, TextInput, CheckboxInput} from 'components/general/form';
import {alert} from 'alert';
import {API_URL} from 'config';

const CreateLicense = ()=>{
    let {auth} = useContext(AuthContext);
    let [newLicense, setNewLicense] = useState({order_id: 'manual-', shop: '', expires: -1, on_hold: false});
    let [saving, setSaving] = useState(false);

    const createLicense = async ()=>{
        setSaving(true);
        let {expires, shop, on_hold, ...tempLicense} = newLicense;
        let now = new Date().getTime();
        if(expires == '' || expires == -1 || typeof expires == 'string' && expires.trim().toLowerCase() == 'never'){
            expires = -1;
        } else {
            expires = expires * 1000 * 86400 + now;
        }
        if(shop.includes('://')){
            let tempShop = new URL(shop);
            shop = tempShop.hostname;
        }
        if(typeof on_hold != 'boolean'){
            on_hold = false;
        }
        tempLicense = {expires, shop, on_hold, ...tempLicense};
        let newlicense = await auth.ajax(API_URL+'/v1/license/create', 
            {
                method: 'POST', 
                body: JSON.stringify(tempLicense)
            }
        );
        setSaving(false);
        if(!newlicense.ok){
            return alert.error(newlicense.error);
        }
        alert.success(`License with an ID: ${newlicense.license.id}, created successfully!`);
    }

    return <React.Fragment>
        <BreadcrumbBack href="/licenses" text="Back to all licenses" />         
            <React.Fragment>
                <Heading loading={saving} text='Create a license' />
                <Form initial={newLicense} callback={setNewLicense}>
                    <TextInput autocomplete="off" label="Order ID" objKey="order_id" />
                    <TextInput autocomplete="off" label="Shop (domain)" objKey="shop" />
                    <TextInput autocomplete="off" label="Owner (e-mail)" objKey="owner" />
                    <TextInput autocomplete="off" label="Expires (days)" objKey="expires" />
                    <CheckboxInput label="On hold? " objKey="on_hold" />
                    <Button inProgress={{state: saving, text: 'Creating a license...'}} onClick={createLicense} type="primary">Create a License</Button>
                </Form>
            </React.Fragment>    
    </React.Fragment>
}

export {
    CreateLicense
}