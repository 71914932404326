import {Switch, Route} from 'react-router-dom';
import {UserRoute, RoleRoute} from './protected';
import {Login} from 'pages/login';
import React from 'react';
import {Frame} from 'components/frame';
import {ManageAgents} from 'pages/agents';
import {ManageAgent} from 'pages/agents/manage';
import {CreateAgent} from 'pages/agents/create';
import { ManageLicenses } from 'pages/licenses';
import {ManageLicense} from 'pages/licenses/manage';
import { CreateLicense } from 'pages/licenses/create';
import { Dashboard } from 'pages/home';
import { ViewLicense } from 'pages/licenses/view';
import { ManageFraud } from 'pages/fraud';
import {ManageThemes} from 'pages/app_themes';
import {ManagePresets} from 'pages/app_presets';
import {AddTheme} from 'pages/app_themes/add';
import { AddPreset } from 'pages/app_presets/add';
import { AddNotification } from 'pages/notifications/add';
import { ManageNotifications } from 'pages/notifications';
import { EditNotification } from 'pages/notifications/edit';

const Routes = ()=>{
    return <Switch>
        <Route exact path="/login">
            <Login  />
        </Route>
        <RoleRoute role="admin" path="/agents/create">
            <Frame>
                <CreateAgent />
            </Frame>
        </RoleRoute>
        <RoleRoute role="admin" path="/agents/manage/:agent">
            <Frame>
                <ManageAgent />
            </Frame>
        </RoleRoute>
        <RoleRoute role="admin" path="/themes/add">
            <Frame>
                <AddTheme />
            </Frame>
        </RoleRoute>
        <RoleRoute role="admin" path="/presets/add">
            <Frame>
                <AddPreset />
            </Frame>
        </RoleRoute>
        <RoleRoute role="admin" path="/presets">
            <Frame>
                <ManagePresets />
            </Frame>
        </RoleRoute>
        <RoleRoute role="admin" path="/notifications/add">
            <Frame>
                <AddNotification />
            </Frame>
        </RoleRoute>
        <RoleRoute role="admin" path="/notifications/edit/:id">
            <Frame>
                <EditNotification />
            </Frame>
        </RoleRoute>
        <RoleRoute role="admin" path="/notifications">
            <Frame>
                <ManageNotifications />
            </Frame>
        </RoleRoute>
        <RoleRoute role="admin" path="/themes">
            <Frame>
                <ManageThemes />
            </Frame>
        </RoleRoute>
        <RoleRoute role="admin" path="/agents">
            <Frame>
                <ManageAgents />
            </Frame>
        </RoleRoute>
        <UserRoute path="/fraud/:page?">
            <Frame>
                <ManageFraud />
            </Frame>
        </UserRoute>
        <UserRoute path="/licenses/create">
            <Frame>
                <CreateLicense />
            </Frame>
        </UserRoute>
        <UserRoute path="/licenses/manage/:license">
            <Frame>
                <ManageLicense />
            </Frame>
        </UserRoute>
        <UserRoute path="/licenses/view/:license">
            <Frame>
                <ViewLicense />
            </Frame>
        </UserRoute>
        <UserRoute path="/licenses/:page?">
            <Frame>
                <ManageLicenses />
            </Frame>
        </UserRoute>
        <UserRoute path="/">
            <Frame>
                <Dashboard />
            </Frame>
        </UserRoute>
    </Switch>
}

export {
    Routes
}