import React, {useEffect, useState, useContext, useRef} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import RouteContext from 'router/context';
import {Heading} from 'components/general/heading';
import {alert} from 'alert';
import {Table} from 'components/general/table';
import {Form, TextInput, CheckboxInput} from 'components/general/form';
import {BsPlus, BsGearFill, BsEyeFill} from 'react-icons/bs';
import {Link, useHistory} from 'react-router-dom';
import './licenses.css';
import {API_URL} from 'config';

const ManageLicenses = ()=>{
    let {auth} = useContext(AuthContext);
    let {match} = useContext(RouteContext);
    let [licenses, setLicenses] = useState([]);
    let [pagination, setPagination] = useState({prev: false, next: false});
    let [currentPage, setCurrentPage] = useState(match.params.page || 1)
    let [searchSync, setSearchSync] = useState(false)
    let [debounced, setDebounced] = useState(false);
    let initSP;
    try{
        let q = new URLSearchParams(window.location.search);
        initSP = JSON.parse(decodeURI(q.get('s')));
    }catch{
        initSP = false;
    }
    let [searchParams, setSearchParams] = useState(initSP || {value: '', active: true, on_hold: true, expired: true, targets: {'shop': true, 'owner': false, 'order_id': false}});
    let [loading, setLoading] = useState(true);
    let [total, setTotal] = useState(0);
    let now = new Date().getTime();
    let history = useHistory();
    const by = 25;
    const firstRun = useRef(true);
    const initRun = useRef(0);

    const getLicenses = async ()=>{
        setLoading(true);
        let page = parseInt(currentPage);
        let url;
        if(searchParams.expired){
            url = new URL(API_URL + `/v1/license/all/${page}:${by}`);
        }else{
            url = new URL(API_URL + `/v1/license/active/${page}:${by}`);
        }
        let targets = searchParams.targets;
        let value = searchParams.value;
        if(value != ''){
            for(let target of Object.keys(targets)){
                if(!targets[target]){
                    continue;
                }
                url.searchParams.append(target, `%${value}%`);
            }
        }
        let active = searchParams.active;
        let on_hold = searchParams.on_hold;
        if((!active || !on_hold) && !(!active && !on_hold)){
            if(active){
                url.searchParams.append('on_hold', false);
            } else {
                url.searchParams.append('on_hold', true);
            }
        }
        let res = await auth.ajax(url);
        if(res.ok){
            if(res.results.length){
                setLicenses(res.results);
                let tempPag = {prev: false, next: false};
                if(page > 1){
                    tempPag.prev = page-1;                    
                }
                if(page * by < res.results[0].total_results){
                    tempPag.next = page+1;
                }
                setTotal(res.results[0].total_results);
                setPagination(tempPag);
            } else {
                setLicenses([]);
                alert.error('No licenses found with the set criteria.');
            }
            return setLoading(false);
        } else {
            return alert.error('Error getting licenses');
        }
    }

    const changeSearch = ()=>{
        if(initRun.current < 2){
            return ++initRun.current;
        }
        if(debounced){
            clearTimeout(debounced);
        }
        
        let newTimeout = setTimeout(()=>{
            history.push('?s='+ encodeURI(JSON.stringify(searchParams)));
            if(parseInt(currentPage) == 1){
                getLicenses();
            } else {
                handlePage(1);
            }
            setDebounced(false);
        }, 500);

        setDebounced(newTimeout);
    }

    useEffect(
        ()=>{
            changeSearch();
        },
        [searchParams]
    )

    const handlePage = (page)=>{
        const q = new URLSearchParams(window.location.search);
        history.push({pathname: '/licenses/'+page, search: '?s=' + q.get('s')});
        setCurrentPage(page)
    }

    useEffect(
        ()=>{
            getLicenses();
        },
        []
    )

    const changePage = ()=>{
        if(firstRun.current){
            firstRun.current = false;
            return;
        }
        getLicenses();
    }

    useEffect(()=>{
        changePage();
    }, [currentPage]);

    useEffect(()=>{
        const q = new URLSearchParams(window.location.search);
        if(q.get('s')){
            try{
            let sp = decodeURI(q.get('s'))
            if(sp != JSON.stringify(searchParams)){
                setSearchParams(JSON.parse(sp))
                setSearchSync(JSON.parse(sp))
            }
            }catch(err){

            }
        }
    }, [window.location.search])

    const Pagination = ()=><div className="pagination">
                            <Button disabled={ loading || !pagination.prev} onClick={()=>handlePage(pagination.prev)} type="secondary">Prev</Button>
                            <Button disabled={loading || !pagination.next} onClick={()=>handlePage(pagination.next)} type="secondary">Next</Button>
                            Showing {currentPage * by - by} - {currentPage * by} / {total} (Page {currentPage})
                        </div>

    return <React.Fragment>
        <React.Fragment>
            <Heading loading={loading} text={`Manage licenses ${searchParams.value && `(${searchParams.value})` || ''}`}>
                <Link to="/licenses/create">
                    <Button type="primary"><BsPlus /> Create a License</Button>
                </Link>
            </Heading>
            <div className="license__search__holder">
                <Form unset key={searchSync} initial={searchParams} className='license__search' callback={setSearchParams}>
                    <TextInput objKey='value' label='Search' />
                    <div className='license__search__targets'>
                        <div>
                            Search by:
                        </div>
                        <div className="license__search__filter">
                            <CheckboxInput objKey='targets.shop' label='Shop' />
                            <CheckboxInput objKey='targets.order_id' label='Order ID' />
                            <CheckboxInput objKey='targets.owner' label='Owner' />
                        </div>
                    </div>
                    <div className='license__search__targets'>
                        <div>
                            Filter by:
                        </div>
                        <div className="license__search__filter">
                            <CheckboxInput objKey='active' label='Active' />
                            <CheckboxInput objKey='on_hold' label='On hold' />
                            <CheckboxInput objKey='expired' label='Show expired licenses' />
                        </div>
                    </div>
                </Form>
            </div>
            <div className="page__content">
                <Pagination />
                <Table header={['Order ID', 'Domain', 'E-mail', 'Expires', 'Status', '', '']}>
                    {
                        licenses.map((v)=>{
                            let expires = v.expires == -1 && 'Never' || Math.floor((parseInt(v.expires) - now)/1000/86400);
                            if(expires < 0){
                                expires = 'Expired';
                            } else if(expires > 0){
                                expires += ' days';
                            }

                            return (
                            <tr>
                                <td>{v.order_id}</td>
                                <td>{v.shop && <a target="_blank" href={'https://'+v.shop}>{'https://'+v.shop}</a> || 'Unclaimed' }</td>
                                <td>{v.owner && <a href={'mailto:'+v.owner}>{v.owner}</a> || 'No owner found'}</td>
                                <td>{expires}</td>
                                <td>
                                    <span className={`license__status license__status--${v.on_hold && 'hold' || 'active'}`}></span>
                                </td>
                                <td className="util"><Link to={{pathname: `/licenses/manage/${v.id}`, state: {search: window.location.search}}}><BsGearFill className="agents__manage" /></Link></td>
                                <td className="util"><Link to={{pathname: `/licenses/view/${v.id}`, state: {search: window.location.search}}}><BsEyeFill className="agents__manage" /></Link></td>
                            </tr>
                            )
                        })
                    }
                </Table>
                <Pagination />
            </div>
        </React.Fragment>
    </React.Fragment>
}

export {
    ManageLicenses
}