import React, {useContext, useState, useEffect} from 'react';
import FormContext from './context';
import {getKey} from './path';

const CheckboxInput = ({objKey, label, initial, ...props})=>{
    let key = objKey;
    let {obj, updateValue} = useContext(FormContext);
    initial = props.initial || (obj && getKey(obj, key, false)) || false;
    let [value, setValue] = useState(initial)

    const handleChange = (event)=>{
        if(props.disabled){
            return;
        }
        setValue(event.target.checked);
    }

    useEffect(()=>{
        updateValue(key, value)
    }, [value])

    return <React.Fragment>
        <div className="form__group">
            <label className="form__label" for={key}>
                <input disabled={props.disabled} className="form__input" id={key} type={'checkbox'} checked={value} onChange={handleChange} {...props} />
                {label}
            </label>  
        </div>
    </React.Fragment>
}

export default CheckboxInput;