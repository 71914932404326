import React, {useEffect, useState, useContext} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import RouteContext from 'router/context';
import {BreadcrumbBack} from 'components/general/navigation';
import {Heading} from 'components/general/heading';
import {Form, TextInput, CheckboxInput} from 'components/general/form';
import {alert} from 'alert';
import {API_URL} from 'config';
import { useLocation } from 'react-router-dom';

const ManageLicense = ()=>{
    let {auth} = useContext(AuthContext);
    let {match} = useContext(RouteContext);
    let [license, setLicense] = useState(false);
    let [newLicense, setNewLicense] = useState(false);
    let [loading, setLoading] = useState(true);
    let [saving, setSaving] = useState(false);
    let location = useLocation();

    const getLicense = async ()=>{
        let res = await auth.ajax(API_URL+'/v1/license/'+match.params.license);
        if(res.ok){
            let {expires, ...tempLicense} = res.license;
            let now = new Date().getTime();
            if(expires == -1){
                tempLicense.expires = 'Never'
            } else {
                tempLicense.expires = Math.ceil((expires - now) / 1000 / 86400);
            }
            setNewLicense(tempLicense);
            setLicense(tempLicense);
        } else {
            alert.error(JSON.stringify(res.error));
        }
        return setLoading(false);
    }

    useEffect(()=>{
        getLicense();
    }, []);

    const updatelicense = async ()=>{
        setSaving(true);
        let {expires, shop, ...updateInfo} = newLicense;
        shop = shop || '';
        let now = new Date().getTime();
        if(typeof expires == 'string' && expires.trim().toLowerCase() == 'never'){
            expires = -1;
        } else {
            expires = expires * 1000 * 86400 + now;
        }
        if(shop.includes('://')){
            let tempShop = new URL(shop);
            shop = tempShop.hostname;
        }
        let updateReq = {expires, shop, ...updateInfo};
        console.log(updateReq);
        let res = await auth.ajax(API_URL+'/v1/license/update', 
            {
                method: 'POST', 
                body: JSON.stringify(updateReq)
            }
        );
        setSaving(false);
        if(!res.ok){
            return alert.error(res.error);
        }
        setLicense(res.license);
        alert.success('License updated successfully', 'success');
    }

    let isChanged = JSON.stringify(license) == JSON.stringify(newLicense);

    let licenseTitle = license ? `#${license.order_id} (${license.id})` : '';

    return <React.Fragment>
        <BreadcrumbBack href={"/licenses"+((location.state && location.state.search && location.state.search) || '')} text="Back to all licenses" />
        {
            loading &&
            <Heading loading={loading} text="Loading..."/> ||
            (!license && 
            <React.Fragment>
                <Heading text={`License could not be found.`}/>
            </React.Fragment> ||
            <React.Fragment>
                <Heading loading={saving} text={`Manage a license: ${licenseTitle}`}/>
                <Form initial={newLicense} callback={setNewLicense}>
                    <TextInput autocomplete="off" disabled label="ID" objKey="id" />
                    <TextInput autocomplete="off" label="Order ID" objKey="order_id" />
                    <TextInput autocomplete="off" label="Shop (domain)" objKey="shop" />
                    <TextInput autocomplete="off" label="Owner" objKey="owner" />
                    <TextInput autocomplete="off" label="Expires in (days)" objKey="expires" />
                    <CheckboxInput label="On hold? " objKey="on_hold" />
                    <Button inProgress={{state: saving, text: 'Updating license...'}} disabled={isChanged} onClick={updatelicense} type="primary">Update license</Button>
                </Form>
            </React.Fragment>
            )
        }
    </React.Fragment>
}

export {
    ManageLicense
}