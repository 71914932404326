import React from 'react';
import {Link} from 'react-router-dom';
import {BsArrowLeftShort} from 'react-icons/bs';
import './back.css';

const BreadcrumbBack = ({text, ...props})=>{
    return <>
        {
            props.href &&
            <Link to={props.href} className="breadcrumbs__back">
                <BsArrowLeftShort className="breadcrumbs__back__icon" /><span>{text || 'Back'}</span>
            </Link> ||
            <a {...props} className="breadcrumbs__back">
                <BsArrowLeftShort className="breadcrumbs__back__icon" /><span>{text || 'Back'}</span>
            </a>
        }
    </>
}

export default BreadcrumbBack;