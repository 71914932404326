import Cookies from 'js-cookie';
import {BehaviorSubject} from 'rxjs';
import {API_URL} from 'config';

class Auth{
    constructor(){
        this._initUser = JSON.parse(localStorage.getItem('currentUser')) || false;
        this.userSubject = new BehaviorSubject(this._initUser);
        this.access = Cookies.get('accessJWT') || '';
        this.refresh = Cookies.get('refreshJWT') || '';
        this.initUser();
    }

    async login(data){
        let res;
        try {
            res = await fetch(API_URL+'/v1/auth', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            });
        }catch(err){
            return {ok: false, error: 'A network error occured.'};
        }
        if(!res.ok){
            return {ok: false, error: 'Invalid credentials. Try again.'};
        }
        let json = await res.json();
        if(!json.ok){
            return {ok: false, error: 'Invalid credentials. Try again.'};
        }
        this.setUser(json.user);
        this.setAccess(json.access);
        this.setRefresh(json.refresh);
        return {ok: true};
    }

    logout(){
        this.removeUser();
        this.removeAccess();
        this.removeRefresh();
    }

    async initUser(){
        if(this._initUser){
            return;
        }
        let data = await this.ajax(API_URL+'/v1/auth/user');
        if(!data.ok){
            return;
        }
        this.userSubject.next(data.user);
    }

    setAccess(token){
        this.access = token;
        Cookies.set('accessJWT', token);
    }

    setRefresh(token){
        this.refresh = token;
        Cookies.set('refreshJWT', token, {expires: 1});
    }

    setUser(user){
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.userSubject.next(user);
    }

    removeAccess(){
        this.access = false;
        Cookies.remove('accessJWT');
    }

    removeRefresh(){
        this.refresh = false;
        Cookies.remove('refreshJWT');
    }

    removeUser(){
        localStorage.removeItem('currentUser');
        this.userSubject.next(false);
    }

    getHeaders(){
        if(!this.access){
            return {};
        }
        return {'Authorization': `Bearer ${this.access}`}
    }

    async ajax(url, config = {}){
        if(!config.headers){
            config.headers = this.getHeaders();
        } else {
            config.headers = {...config.headers, ...this.getHeaders()}
        }
        if(config.method == 'POST' && config.setJSON != '-1'){
            config.headers["Content-Type"] = "application/json";
        }
        let res;
        try{
            res = await fetch(url, config);
        }catch(err){
            return {ok: false, error: 'Network error.'};
        }
        if(!res.ok){
            if(res.status == 401){
                this.logout();
                return {ok: false, error: 'Unauthenticated.'};
            }
            try{
                let data = await res.json();
                let error = data.error || res.status;
                return {ok: false, error}
            }catch(e){
                return {ok: false, error: res.status};
            }
        }
        let data = await res.json();
        if(!data.ok && data.error == 'TOKEN_EXPIRED'){
            if(!this.refresh.length){
                this.logout();
                return {ok: false, error: ''};
            }
            let newRefresh = await this.refreshToken();
            if(!newRefresh.ok){
                this.logout();
                return {ok: false, error: 'Unauthenticated.'};
            }
            let retryAjax = await this.ajax(url, config);
            return retryAjax;
        }
        if(!data.ok && data.error == 'BAD_ROLE'){
            this.logout();
            return {ok: false, error: 'Insufficient permissions.'};
        }
        return data;
    }

    async refreshToken(){
        let headers = this.getHeaders();
        headers["Content-Type"] = "application/json";
        let res = await fetch(API_URL+'/v1/auth/refresh', {
            method: 'POST',
            body: JSON.stringify({token: this.refresh}),
            headers: headers
        });
        if(!res.ok){
            return {ok: false, error: 'Network error.'};
        }
        let data = await res.json();
        if(!data.ok){
            return data;
        }
        this.setAccess(data.access);
        return {ok: true};
    }
}

const authService = new Auth();

export {
    authService
}