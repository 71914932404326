import React, {useState, useImperativeHandle, forwardRef} from 'react';
import './modal.css';
import {BsXCircleFill} from 'react-icons/bs';
import {Button} from 'components/general/button';

const Modal = forwardRef((props, ref)=>{
    let [open, setOpen] = useState(props.open || false);
    let self = this;

    useImperativeHandle(ref, () => ({
        openModal, closeModal
    }));

    const openModal = ()=>{
        setOpen(true);
        if(typeof props.openCallback == 'function'){
            props.openCallback();
        }
    }

    const closeModal = ()=>{
        setOpen(false);
        if(typeof props.closeCallback == 'function'){
            props.closeCallback();
        }
    }

    const confirm = ()=>{
        setOpen(false);
        if(typeof props.confirmCallback == 'function'){
            props.confirmCallback();
        }
    }

    const cancel = ()=>{
        setOpen(false);
        if(typeof props.cancelCallback == 'function'){
            props.cancelCallback();
        }
    }

    return <React.Fragment>
    {open &&
        <React.Fragment>
            <div onClick={()=>closeModal()} className="modal__overlay"></div>
        <div className="modal">
            <div className="modal__header">
                <h2>{props.title}</h2>
                <BsXCircleFill onClick={()=>closeModal()} className="modal__close" />
            </div>
            <div className="modal__content">
                {props.text}
                {props.children}
            </div>
            <div className="modal__buttons">
                <Button type="secondary" onClick={cancel}>{props.cancelText || 'Cancel'}</Button>
                <Button type="primary" onClick={confirm}>{props.confirmText || 'Confirm'}</Button>
            </div>
        </div>
        </React.Fragment>
    }
    </React.Fragment>
})

export {
    Modal
}