import React, {useEffect, useState, useContext, useRef} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import {Heading} from 'components/general/heading';
import {alert} from 'alert';
import {Table} from 'components/general/table';
import {BsPlus} from 'react-icons/bs';
import {Link} from 'react-router-dom';
import {MANAGER_URL} from 'config';

const AddTheme = ()=>{
    let {auth} = useContext(AuthContext);
    let [versions, setVersions] = useState([]);
    let [loading, setLoading] = useState(true);
    let [disable, setDisable] = useState(false);

    const getVersions = async()=>{
        setLoading(true);
        let res = await auth.ajax(MANAGER_URL + '/versions');
        setLoading(false);
        if(!res.ok){
            setVersions([]);
            return alert.error(res.error);
        }
        setVersions(res.versions);
    }

    useEffect(()=>{
        getVersions();
    }, [])

    const uploadTheme = async()=>{
        setLoading(true);
        setDisable(true);
        let form = document.getElementById('file-form');
        let formData = new FormData(form);
        let res = await auth.ajax(MANAGER_URL + '/upload', {
            method: 'POST',
            body: formData,
            setJSON: '-1'
        });
        setLoading(false);
        setDisable(false);
        if(!res.ok){
            return alert.error(res.error);
        }
        alert.success('Theme successfully uploaded.');
        getVersions();
    }

    return <React.Fragment>
        <React.Fragment>
            <Heading loading={loading} text="Add a theme">
            </Heading>
            <div className="page__content">
                <form id="file-form" className="card--general" method="POST" encType="multipart/form-data">
                    <span className="span--label">Choose a theme file (.zip)</span>
                    <input type="file" name="theme_File" />
                    <span className="spacer--24"></span>
                    <span className="span--label">Select a version</span>
                    <select name="version">
                        {versions.map((v, index)=>{
                            return <option key={index} value={v}>{v}</option>
                        })}
                    </select>
                    <span class="spacer--24"></span>
                    <input type="submit" value="Upload" onClick={() => uploadTheme()} disabled={disable} />
                </form>
            </div>
        </React.Fragment>
    </React.Fragment>
}

export {
    AddTheme
}