import React, {useEffect, useState, useContext, useRef} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import {Heading} from 'components/general/heading';
import {alert} from 'alert';
import {MANAGER_URL} from 'config';

const AddPreset = ()=>{
    let {auth} = useContext(AuthContext);
    let [loading, setLoading] = useState(false);
    let [disable, setDisable] = useState(false);

    const filesToJSON = async()=>{
        let files = [...document.querySelector('[name="settings_data"]').files, ...document.querySelector('[name="template_files"]').files]
        let settings = {}
        for(let file of files){
            if(file.name.includes('settings_data')){
                settings['settings_data.json'] = JSON.parse(await file.text())
            } else {
                settings[file.name.split('/').pop()] = JSON.parse(await file.text())
            }
        }
        return JSON.stringify(settings)
    }

    const uploadTheme = async()=>{
        setLoading(true);
        setDisable(true);

        let form = document.getElementById('file-form');
        let formData = new FormData(form);
        formData.set('json_settings', await filesToJSON())
        let res = await auth.ajax(MANAGER_URL + '/presets', {
            method: 'POST',
            body: formData,
            setJSON: '-1'
        });
        setLoading(false);
        setDisable(false);
        if(!res.ok){
            return alert.error(res.error);
        }
        alert.success('Preset successfully uploaded.');
    }

    return <React.Fragment>
        <React.Fragment>
            <Heading loading={loading} text="Add a preset">
            </Heading>
            <div className="page__content">
                <div className="card--general">
                    <span className="span--label">Choose settings_data.json</span>
                    <input type="file" name="settings_data" />
                    <span className="spacer--24"></span>
                    <span className="span--label">Choose template files (templates/*.json)</span>
                    <input type="file" multiple name="template_files" />
                    <form id="file-form">
                        <span className="spacer--24"></span>
                        <span className="span--label">Choose a thumbnail (image)</span>
                        <input type="file" name="thumbnail" />
                        <span className="spacer--24"></span>
                        <span className="span--label">Name</span>
                        <input type="text" name="name" /> 
                        <span className="spacer--24"></span>
                        <span className="span--label">Demo store</span>
                        <input type="text" name="demostore" /> 
                        <span className="spacer--24"></span>
                        <Button onClick={(event)=>uploadTheme(event)} disabled={disable} type="primary">Upload</Button>
                    </form>
                </div>
            </div>
        </React.Fragment>
    </React.Fragment>
}

export {
    AddPreset
}