import React, {useRef, useEffect, useState, useContext} from 'react';
import AuthContext from 'auth/context';
import './agents.css';
import {BsTrashFill, BsGearFill, BsPlus} from 'react-icons/bs';
import {Modal} from 'components/general/modal';
import {Table} from 'components/general/table';
import {Link} from 'react-router-dom';
import {Heading} from 'components/general/heading';
import { Button } from 'components/general/button';
import {alert} from 'alert';
import {API_URL} from 'config';

const ManageAgents = (props)=>{
    let {auth} = useContext(AuthContext);
    let [agents, setAgents] = useState([]);
    let [delTarget, setDelTarget] = useState(false);
    const modal = useRef(null);

    const getAgents = async ()=>{
        let res = await auth.ajax(API_URL+'/v1/agents/all');
        if(res.ok){
            let agents = res.agents;
            agents.sort((a, b)=> a.id - b.id);
            setAgents(agents);
        }
    }

    useEffect(()=>{
        getAgents();
    }, []);

    const deletePromp = (agent)=>{
        setDelTarget(agent);
        modal.current.openModal();
    }

    const deleteAgent = async ()=>{
        let res = await auth.ajax(API_URL+'/v1/agents/remove', {
            method: 'POST',
            body: JSON.stringify({username: delTarget.username})
        });
        console.log(delTarget);
        if(!res.ok){
            return alert.error(res.error);
        }
        alert.success(`Agent ${delTarget.username} deleted successfully!`);
        setDelTarget({});
        return getAgents();
    }

    return <React.Fragment>
        <Heading loading={!agents.length} text="Manage agents">
            <Link to="/agents/create">
                <Button type="primary"><BsPlus /> Create an agent</Button>
            </Link>
        </Heading>
        <div className="page__content">
            <Table header={['ID', 'Username', 'E-mail', 'Password', '', '']}>
                {
                    agents.map((v)=>{
                        return (
                        <tr>
                            <td>{v.id}</td>
                            <td>{v.username}</td>
                            <td>{v.email}</td>
                            <td>*********</td>
                            <td className="util"><Link to={`/agents/manage/${v.id}`}><BsGearFill className="agents__manage" /></Link></td>
                            <td className="util"><BsTrashFill onClick={()=>deletePromp(v)} className="agents__trash" /></td>
                        </tr>
                        )
                    })
                }
            </Table>
            <Modal title="Are you sure?" closeCallback={()=>''} text={`Are you sure you want to delete an agent: ${delTarget.username}?`} cancelCallback={()=>''} confirmCallback={()=>{deleteAgent()}} ref={modal} />
        </div>
    </React.Fragment>
}

export {
    ManageAgents
}