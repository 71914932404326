import {Route, Redirect} from 'react-router-dom';
import React, {useState, useContext, useEffect} from 'react';
import AuthContext from 'auth/context';
import RouteContext from './context';

const UserRoute = ({ children, role, ...rest })=>{
    let {currentUser} = useContext(AuthContext);

    return (
      <Route exact
        {...rest}
        render={
            (routeprops) =>
            currentUser ? (
            <RouteContext.Provider value={{...routeprops}}>
                {children}
            </RouteContext.Provider>
          ) : (
            <Redirect
              to={`/login?redirect=${routeprops.match.url}`}
            />
          )
        }
      />
    );
  }

const RoleRoute = ({ children, role, ...rest })=>{
    let {auth, currentUser} = useContext(AuthContext);
    let [redirect, setRedirect] = useState(false);

    useEffect(()=>{
        if (!['Marlon','Milven','vivek'].includes(currentUser.username)){
            if(currentUser && currentUser.role != role){
                setRedirect('/');
                return auth.logout();
            }
        }
    }, []);

    return (
      <Route exact
        {...rest}
        render={
            (routeprops) =>
            currentUser ? (
            <RouteContext.Provider value={{...routeprops}}>
                {children}
            </RouteContext.Provider>
          ) : (
            <Redirect
              to={`/login?redirect=${redirect || routeprops.match.url}`}
            />
          )
        }
      />
    );
  }

export {
    UserRoute,
    RoleRoute
}