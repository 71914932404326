import React, {useContext, useState, useEffect} from 'react';
import FormContext from './context';
import {getKey} from './path';

const TextInput = ({objKey, label, initial, type, ...props})=>{
    let key = objKey;
    let {obj, updateValue} = useContext(FormContext);
    let init = initial || (obj && getKey(obj, key, '')) || '';
    type = type || 'text';
    let [value, setValue] = useState(init)

    const handleChange = (event)=>{
        if(props.disabled){
            return;
        }
        setValue(event.target.value.trim());
    }

    useEffect(()=>{
        updateValue(key, value)
    }, [value])

    return <React.Fragment>
        <div className="form__group">
            <label className="form__label" for={key}>{label}</label>
            <input className="form__input" id={key} type={type} value={value} onChange={handleChange} {...props} />
        </div>
    </React.Fragment>
}

export default TextInput;