import React, {useEffect, useState, useContext, useRef} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import {Heading} from 'components/general/heading';
import {alert} from 'alert';
import {Table} from 'components/general/table';
import {BsPlus, BsTrashFill} from 'react-icons/bs';
import {Link} from 'react-router-dom';
import {MANAGER_URL} from 'config';
import {Modal} from 'components/general/modal';

const ManageThemes = ()=>{
    let {auth} = useContext(AuthContext);
    let [themes, setThemes] = useState([]);
    let [loading, setLoading] = useState(true);
    let [delTarget, setDelTarget] = useState(null);
    const modal = useRef(null);

    const getThemes = async()=>{
        setLoading(true);
        let res = await auth.ajax(MANAGER_URL + '/themes');
        setLoading(false);
        if(!res.ok){
            return alert.error(res.error);
        }
        setThemes(res.themes);
    }

    useEffect(()=>{
        getThemes();
    }, [])

    const deleteTheme = async()=>{
        let id = delTarget;
        let res = await auth.ajax(MANAGER_URL + '/theme/' + id, {
            method: 'DELETE'
        });
        if(!res.ok){
            return alert.error(res.error);
        }
        alert.success('Theme ' + id + ' deleted.');
        getThemes();
    }

    const delPrompt = async(id)=>{
        setDelTarget(id);
        modal.current.openModal();
    }

    return <React.Fragment>
        <React.Fragment>
            <Heading loading={loading} text="Manage themes">
                <Link to="/themes/add">
                    <Button type="primary"><BsPlus /> Add a theme</Button>
                </Link>
            </Heading>
            <div className="page__content">
                <Table header={['ID', 'URL', 'Version', 'Added', '']}>
                    {
                        themes.sort((a, b) => (a.added < b.added) ? 1 : -1).map((v)=>{
                            return (
                            <tr>
                                <td>{v.id}</td>
                                <td><a href={v.file}>{v.file.slice(0, 24)}..</a></td>
                                <td>{v.version}</td>
                                <td>{new Date(parseInt(v.added)).toLocaleString()}</td>
                                <td className="util"><BsTrashFill onClick={()=>delPrompt(v.id)} /></td>
                            </tr>
                            )
                        })
                    }
                </Table>
            </div>
            <Modal title="Are you sure?" closeCallback={()=>setDelTarget(false)} text={`Are you sure you want to delete the theme with an id: ${delTarget}?`} cancelCallback={()=>''} confirmCallback={()=>{deleteTheme()}} ref={modal} />
        </React.Fragment>
    </React.Fragment>
}

export {
    ManageThemes
}