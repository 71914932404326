import React, {useEffect, useState, useContext, useRef} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import RouteContext from 'router/context';
import {Heading} from 'components/general/heading';
import {alert} from 'alert';
import {Table} from 'components/general/table';
import {Form, TextInput, CheckboxInput} from 'components/general/form';
import {BsPlus, BsEyeFill} from 'react-icons/bs';
import {Link, useHistory} from 'react-router-dom';
import './licenses.css';
import {API_URL} from 'config';

const ManageFraud = ()=>{
    let {auth} = useContext(AuthContext);
    let {match} = useContext(RouteContext);
    let [licenses, setLicenses] = useState([]);
    let [pagination, setPagination] = useState({prev: false, next: false});
    let [currentPage, setCurrentPage] = useState(match.params.page || 1)
    let [debounced, setDebounced] = useState(false);
    let [searchParams, setSearchParams] = useState({value: '', targets: {'shop': true, 'owner': false, 'version': false}});
    let [loading, setLoading] = useState(true);
    let [total, setTotal] = useState(0);
    let now = new Date().getTime();
    let history = useHistory();
    const by = 25;
    const firstRun = useRef(true);
    const initRun = useRef(0);

    const getLicenses = async ()=>{
        setLoading(true);
        let page = parseInt(currentPage);
        let url = new URL(API_URL + `/v1/license/fraud/${page}:${by}`);
        let targets = searchParams.targets;
        let value = searchParams.value;
        if(value != ''){
            for(let target of Object.keys(targets)){
                if(!targets[target]){
                    continue;
                }
                url.searchParams.append(target, `%${value}%`);
            }
        }
        let res = await auth.ajax(url);
        if(res.ok){
            if(res.results.length){
                setLicenses(res.results);
                let tempPag = {prev: false, next: false};
                if(page > 1){
                    tempPag.prev = page-1;                    
                }
                if(page * by < res.results[0].total_results){
                    tempPag.next = page+1;
                }
                setTotal(res.results[0].total_results);
                setPagination(tempPag);
            } else {
                setLicenses([]);
                alert.error('No licenses found with the set criteria.');
            }
            return setLoading(false);
        } else {
            return alert.error('Error getting licenses');
        }
    }

    const changeSearch = ()=>{
        if(initRun.current < 2){
            return ++initRun.current;
        }
        if(debounced){
            clearTimeout(debounced);
        }
        
        let newTimeout = setTimeout(()=>{
            if(parseInt(currentPage) == 1){
                getLicenses();
            } else {
                handlePage(1);
            }
            setDebounced(false);
        }, 500);

        setDebounced(newTimeout);
    }

    useEffect(
        ()=>{
            changeSearch();
        },
        [searchParams]
    )

    const handlePage = (page)=>{
        history.push('/fraud/'+page);
        setCurrentPage(page)
    }

    useEffect(
        ()=>{
            getLicenses();
        },
        []
    )

    const changePage = ()=>{
        if(firstRun.current){
            firstRun.current = false;
            return;
        }
        getLicenses();
    }

    useEffect(()=>{
        changePage();
    }, [currentPage]);

    const Pagination = ()=><div className="pagination">
                            <Button disabled={ loading || !pagination.prev} onClick={()=>handlePage(pagination.prev)} type="secondary">Prev</Button>
                            <Button disabled={loading || !pagination.next} onClick={()=>handlePage(pagination.next)} type="secondary">Next</Button>
                            Showing {currentPage * by - by} - {currentPage * by} / {total} (Page {currentPage})
                        </div>

    return <React.Fragment>
        <React.Fragment>
            <Heading loading={loading} text="Manage licenses">
                <Link to="/licenses/create">
                    <Button type="primary"><BsPlus /> Create a License</Button>
                </Link>
            </Heading>
            <div className="license__search__holder">
                <Form unset initial={searchParams} className='license__search' callback={setSearchParams}>
                    <TextInput objKey='value' label='Search' />
                    <div className='license__search__targets'>
                        <div>
                            Search by:
                        </div>
                        <div className="license__search__filter">
                            <CheckboxInput objKey='targets.shop' label='Shop' />
                            <CheckboxInput objKey='targets.version' label='Version' />
                            <CheckboxInput objKey='targets.owner' label='Owner' />
                        </div>
                    </div>
                </Form>
            </div>
            <div className="page__content">
                <Pagination />
                <Table header={['Internal ID', 'Domain', 'E-mail', 'Version', 'Main', 'Last seen']}>
                    {
                        licenses.map((v)=>{
                            return (
                            <tr>
                                <td>{v.id}</td>
                                <td>{v.shop && <a target="_blank" href={'https://'+v.shop}>{'https://'+v.shop}</a> || 'Unclaimed' }</td>
                                <td>{v.owner && <a href={'mailto:'+v.owner}>{v.owner}</a> || 'No owner found'}</td>
                                <td>{v.version}</td>
                                <td>
                                    <span className={`license__status license__status--${v.main && 'active' || 'expired'}`}></span>
                                </td>
                                <td>{new Date(parseInt(v.last_seen)).toUTCString()}</td>
                                {/* <td className="util"><Link to={`/licenses/view/${v.id}`}><BsEyeFill className="agents__manage" /></Link></td> */}
                            </tr>
                            )
                        })
                    }
                </Table>
                <Pagination />
            </div>
        </React.Fragment>
    </React.Fragment>
}

export {
    ManageFraud
}