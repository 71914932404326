import React, {useEffect, useState, useContext, useRef} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import {Heading} from 'components/general/heading';
import {alert} from 'alert';
import {API_URL} from 'config';
import RouteContext from 'router/context';

const EditNotification = ()=>{
    let {auth} = useContext(AuthContext);
    let {match} = useContext(RouteContext);
    let [loading, setLoading] = useState(false);
    let [disable, setDisable] = useState(false);
    let [notification, setNotification] = useState({});

    const getNotification = async()=>{
        setLoading(true);
        setDisable(true);
        let res = await auth.ajax(API_URL + '/v1/notifications/id/'+match.params.id);
        setLoading(false);
        setDisable(false);
        if(!res.ok){
            return alert.error(res.error);
        }
        setNotification(res.notification);
    }

    useEffect(()=>{
        getNotification();
    }, []);

    const updateValue = (k, v)=>{
        let temp = {...notification};
        temp[k] = v;
        setNotification(temp);
    }

    const updateNotification = async ()=>{
        let json = {...notification};
        let status = document.querySelector('[name="status"]').value;
        json.status = status;
        console.log(json)
        
        let res = await auth.ajax(API_URL + '/v1/notifications/edit', {
            method: 'POST',
            body: JSON.stringify(json)
        })
        if(!res.ok){
            return alert.error(res.error);
        }
        await getNotification();
        return alert.success(res.message);
    }

    return <React.Fragment>
        <React.Fragment>
            <Heading loading={loading} text="Edit a notification">
            </Heading>
            <div className="page__content">
                <form id="file-form" className="card--general">
                    <span class="span--label">Notification text</span>
                    <input type="text" name="text" value={notification.text} onInput={(event)=>updateValue('text', event.target.value)} />
                    <span class="spacer--24"></span>
                    <span class="span--label">Notification URL (can be empty)</span>
                    <input type="text" name="url" value={notification.url} onInput={(event)=>updateValue('url', event.target.value)} />
                    <span class="spacer--24"></span>
                    <span class="span--label">Notification type</span>
                    <select name="status">
                        <option selected={notification.status == 'info' && true}>info</option>
                        <option selected={notification.status == 'warning' && true}>warning</option>
                        <option selected={notification.status == 'error' && true}>error</option>
                    </select>
                    <span class="spacer--24"></span>
                    <Button onClick={(e)=>{e.preventDefault(); updateNotification()}} disabled={disable} type="primary">Edit notification</Button>
                </form>
            </div>
        </React.Fragment>
    </React.Fragment>
}

export {
    EditNotification
}