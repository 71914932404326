import React, {useEffect, useState, useContext, useRef} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import {Heading} from 'components/general/heading';
import {alert} from 'alert';
import {API_URL} from 'config';

const AddNotification = ()=>{
    let {auth} = useContext(AuthContext);
    let [loading, setLoading] = useState(false);
    let [disable, setDisable] = useState(false);

    const uploadNotification = async()=>{
        setLoading(true);
        setDisable(true);
        let form = document.getElementById('file-form');
        let formData = new FormData(form);
        let object = {};
        formData.forEach((value, key) => {object[key] = value});
        let json = JSON.stringify(object);
        console.log(json)
        let res = await auth.ajax(API_URL + '/v1/notifications/create', {
            method: 'POST',
            body: json
        });
        setLoading(false);
        setDisable(false);
        if(!res.ok){
            return alert.error(res.error);
        }
        alert.success('Notification successfully added.');
    }

    return <React.Fragment>
        <React.Fragment>
            <Heading loading={loading} text="Add a notification">
            </Heading>
            <div className="page__content">
                <form id="file-form" className="card--general">
                    <span class="span--label">Notification text</span>
                    <input type="text" name="text" />
                    <span class="spacer--24"></span>
                    <span class="span--label">Notification URL (can be empty)</span>
                    <input type="text" name="url" />
                    <span class="spacer--24"></span>
                    <span class="span--label">Notification type</span>
                    <select name="status">
                        <option>info</option>
                        <option>warning</option>
                        <option>error</option>
                    </select>
                    <span class="spacer--24"></span>
                    <Button onClick={(event)=>uploadNotification(event)} disabled={disable} type="primary">Create a notification</Button>
                </form>
            </div>
        </React.Fragment>
    </React.Fragment>
}

export {
    AddNotification
}