import React, {useEffect, useState, useContext} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import RouteContext from 'router/context';
import {BreadcrumbBack} from 'components/general/navigation';
import {Heading} from 'components/general/heading';
import {Form, TextInput, CheckboxInput} from 'components/general/form';
import {alert} from 'alert';
import {API_URL} from 'config';
import {Link, useLocation} from 'react-router-dom';

const stripeForm = {
    verticalAlign: 'top',
    marginLeft: '32px'
}

const ViewLicense = ()=>{
    let {auth} = useContext(AuthContext);
    let {match} = useContext(RouteContext);
    let [license, setLicense] = useState(false);
    let [newLicense, setNewLicense] = useState(false);
    let [loading, setLoading] = useState(true);
    let location = useLocation();

    const getLicense = async ()=>{
        let res = await auth.ajax(API_URL+'/v1/license/'+match.params.license);
        if(res.ok){
            let {expires, ...tempLicense} = res.license;
            let now = new Date().getTime();
            if(expires == -1){
                tempLicense.expires = 'Never'
            } else {
                tempLicense.expires = Math.floor((expires - now) / 1000 / 86400);
            }
            setNewLicense(tempLicense);
            setLicense(tempLicense);
        } else {
            alert.error(JSON.stringify(res.error));
        }
        return setLoading(false);
    }

    useEffect(()=>{
        getLicense();
    }, []);

    const getStripe = async ()=>{
        let res = await auth.ajax('https://theme.boostertheme.com/v1/stripe/user/'+license.stripe.customer);
        console.log(res)
        if(res.ok){
            let subs = {};
            let invoices = [];
            for(let invoice of res.customer.data){
                subs[invoice.subscription.id] = {
                    subscription: {
                        id: invoice.subscription.id,
                        status: invoice.subscription.status,
                        amount: '$' + invoice.subscription.plan.amount_decimal * 0.01
                    }
                }
                invoices.push({
                    id: invoice.id, due: invoice.amount_due, amount_paid: invoice.amount_paid, reason: invoice.billing_reason,
                    pdf: invoice.invoice_pdf,
                    paid: invoice.paid,
                    subtotal: invoice.subtotal,
                    total: invoice.total
                })
            }
            const stripeInfo = {name: license.stripe.name, email: license.stripe.email, subscriptions: subs, invoices}
            document.getElementById('stripeInfo').innerText = JSON.stringify(stripeInfo, null, 4)
        }
    }

    let licenseTitle = license ? `#${license.order_id} (${license.id})` : '';

    return <React.Fragment>
        <BreadcrumbBack href={"/licenses"+((location.state && location.state.search && location.state.search) || '')} text="Back to all licenses" />
        {
            loading &&
            <Heading loading={loading} text="Loading..."/> ||
            (!license && 
            <React.Fragment>
                <Heading text={`License could not be found.`}/>
            </React.Fragment> ||
            <React.Fragment>
                <Heading text={`Viewing a license: ${licenseTitle}`}/>
                <Form initial={newLicense} callback={setNewLicense}>
                    <TextInput disabled autocomplete="off" label="ID" objKey="id" />
                    <TextInput disabled autocomplete="off" label="Order ID" objKey="order_id" />
                    <TextInput disabled autocomplete="off" label="Shop (domain)" objKey="shop" />
                    <TextInput disabled autocomplete="off" label="Owner" objKey="owner" />
                    <TextInput disabled autocomplete="off" label="Expires in (days)" objKey="expires" />
                    <CheckboxInput disabled label="On hold? " objKey="on_hold" />
                    <Link to={'/licenses/manage/'+license.id}>
                        <Button type="primary">Manage license</Button>
                    </Link>
                </Form>
                {(license.stripe != null) &&
                <Form style={stripeForm} initial={newLicense} callback={setNewLicense}>
                    <p style={{whiteSpace: 'pre'}} id="stripeInfo"></p>
                    <Button onClick={()=>getStripe()} type="primary">Log stripe</Button>
                </Form>}
            </React.Fragment>
            )
        }
    </React.Fragment>
}

export {
    ViewLicense
}