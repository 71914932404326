import React, {useEffect, useState, useContext, useRef} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import {Heading} from 'components/general/heading';
import {alert} from 'alert';
import {Table} from 'components/general/table';
import {BsPlus, BsTrashFill, BsGearFill} from 'react-icons/bs';
import {Link} from 'react-router-dom';
import {Modal} from 'components/general/modal';
import { API_URL } from 'config';

const ManageNotifications = ()=>{
    let {auth} = useContext(AuthContext);
    let [presets, setPresets] = useState([]);
    let [loading, setLoading] = useState(true);
    let [delTarget, setDelTarget] = useState(null);
    const modal = useRef(null);

    const getNotifications = async()=>{
        setLoading(true);
        let res = await auth.ajax(API_URL + '/v1/notifications/all');
        setLoading(false);
        if(!res.ok){
            setPresets([]);
            return alert.error(res.error);
        }
        setPresets(res.notifications);
    }

    useEffect(()=>{
        getNotifications();
    }, [])

    const deleteNotification = async()=>{
        let id = delTarget;
        let res = await auth.ajax(API_URL + '/v1/notifications/delete/' + id, {
            method: 'DELETE'
        });
        if(!res.ok){
            return alert.error(res.error);
        }
        alert.success('Notification ' + id + ' deleted.');
        getNotifications();
    }

    const delPrompt = async(id)=>{
        setDelTarget(id);
        modal.current.openModal();
    }

    return <React.Fragment>
        <React.Fragment>
            <Heading loading={loading} text="Manage notifications">
                <Link to="/notifications/add">
                    <Button type="primary"><BsPlus /> Add a notification</Button>
                </Link>
            </Heading>
            <div className="page__content">
                <Table header={['ID', 'Text', 'URL', 'Status', '', '']}>
                    {
                        presets.map((v)=>{
                            return (
                            <tr>
                                <td>{v.id}</td>
                                <td>{v.text}</td>
                                <td>{v.url}</td>
                                <td>{v.status}</td>
                                <td className="util"><Link to={"/notifications/edit/"+v.id}><BsGearFill /></Link></td>
                                <td className="util"><BsTrashFill onClick={()=>delPrompt(v.id)} /></td>
                            </tr>
                            )
                        })
                    }
                </Table>
            </div>
            <Modal title="Are you sure?" closeCallback={()=>setDelTarget(false)} text={`Are you sure you want to delete the notification with an id: ${delTarget}?`} cancelCallback={()=>''} confirmCallback={()=>{deleteNotification()}} ref={modal} />
        </React.Fragment>
    </React.Fragment>
}

export {
    ManageNotifications
}