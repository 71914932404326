import React, {useEffect, useState, useContext, useRef} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import {Heading} from 'components/general/heading';
import {alert} from 'alert';
import {Table} from 'components/general/table';
import {BsPlus, BsTrashFill} from 'react-icons/bs';
import {Link} from 'react-router-dom';
import {MANAGER_URL} from 'config';
import {Modal} from 'components/general/modal';

const ManagePresets = ()=>{
    let {auth} = useContext(AuthContext);
    let [presets, setPresets] = useState([]);
    let [loading, setLoading] = useState(true);
    let [delTarget, setDelTarget] = useState(null);
    const modal = useRef(null);

    const getThemes = async()=>{
        setLoading(true);
        let res = await auth.ajax(MANAGER_URL + '/new_presets');
        setLoading(false);
        if(!res.ok){
            setPresets([]);
            return alert.error(res.error);
        }
        setPresets(res.presets);
    }

    useEffect(()=>{
        getThemes();
    }, [])

    const deleteTheme = async()=>{
        let id = delTarget;
        let res = await auth.ajax(MANAGER_URL + '/preset/' + id, {
            method: 'DELETE'
        });
        if(!res.ok){
            return alert.error(res.error);
        }
        alert.success('Preset ' + id + ' deleted.');
        getThemes();
    }

    const delPrompt = async(id)=>{
        setDelTarget(id);
        modal.current.openModal();
    }

    return <React.Fragment>
        <React.Fragment>
            <Heading loading={loading} text="Manage presets">
                <Link to="/presets/add">
                    <Button type="primary"><BsPlus /> Add a preset</Button>
                </Link>
            </Heading>
            <div className="page__content">
                <Table header={['ID', 'Name', 'Thumbnail', 'Demo store', '']}>
                    {
                        presets.map((v)=>{
                            return (
                            <tr>
                                <td>{v.id}</td>
                                <td>{v.name}</td>
                                <td><img src={v.thumbnail} width="85" /></td>
                                <td>{v.demo_store || 'No demo store'}</td>
                                <td className="util"><BsTrashFill onClick={()=>delPrompt(v.id)} /></td>
                            </tr>
                            )
                        })
                    }
                </Table>
            </div>
            <Modal title="Are you sure?" closeCallback={()=>setDelTarget(false)} text={`Are you sure you want to delete the preset with an id: ${delTarget}?`} cancelCallback={()=>''} confirmCallback={()=>{deleteTheme()}} ref={modal} />
        </React.Fragment>
    </React.Fragment>
}

export {
    ManagePresets
}