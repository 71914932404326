import React, {useContext} from 'react';
import Icon from './icon';
import AuthContext from 'auth/context';

const Sidebar = (props)=>{
    let {currentUser} = useContext(AuthContext);

    return (
    <div className="frame__sidebar">
        <div className="frame__logo">
            <img src={process.env.PUBLIC_URL + '/mini-logo--accent.png'} />
        </div>
        <div className="frame__sidebar__icons">
            <Icon icon='BsFillGrid1X2Fill' href="/" tooltip="Dashboard" />
            <Icon icon='BsLockFill' href="/licenses/" matches={["/licenses/:page?"]} tooltip="Manage all licenses">
                <Icon hide secondary icon='BsFillPlusSquareFill' matches={["/licenses/create"]} tooltip="Create a license" />
                <Icon hide secondary icon='BsTools' matches={["/licenses/manage/:license"]} tooltip="Manage a license" />
                <Icon hide secondary icon='BsEyeFill' matches={["/licenses/view/:license"]} tooltip="View a license" />
            </Icon>
            <Icon icon='BsFillShieldLockFill' href="/fraud/" matches={["/fraud/:page?"]} tooltip="Manage fraud licenses" />
            {
                currentUser && 
                currentUser.role == 'admin'  &&
                <>
                <Icon icon='BsPeopleFill' href="/agents" matches={["/agents"]} tooltip="Manage all agents">
                    <Icon hide secondary icon='BsPersonPlusFill' matches={["/agents/create"]} tooltip="Create an agent" />
                    <Icon hide secondary icon='BsPersonLinesFill' matches={["/agents/manage/:agent"]} tooltip="Manage an agent" />
                </Icon>
                <Icon icon='BsBellFill' href="/notifications/" tooltip="Manage notifications">
                    <Icon secondary href="/notifications/add" matches={["/notifications/add"]} icon='BsPlusSquareFill' tooltip="Add a notification" />
                    <Icon hide secondary icon='BsTools' matches={["/notifications/edit/:id"]} tooltip="Edit a notification" />
                </Icon>
                </>
            }
            {   currentUser &&
                currentUser.role == 'admin' | ['Marlon','Milven','vivek'].includes(currentUser.username) ?
                (
                <>
                    <Icon icon='BsBrush' href="/themes" tooltip="Manage themes">
                        <Icon secondary href="/themes/add" matches={["/themes/add"]} icon='BsPlusSquareFill' tooltip="Add a theme" />
                    </Icon>
                    <Icon icon='BsFolderFill' href="/presets" tooltip="Manage presets">
                        <Icon secondary href="/presets/add" matches={["/presets/add"]} icon='BsPlusSquareFill' tooltip="Add a preset" />
                    </Icon>
                </>) : null
            }
        </div>
    </div>
    )
}

export {
    Sidebar
}