import React, {useContext, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {LoginForm} from './form';
import AuthContext from 'auth/context';

const Login = (props)=>{
    let {currentUser} = useContext(AuthContext);
    let params = new URLSearchParams(window.location.search);
    let redirect = params.get('redirect') || '/';

    return (
        !currentUser &&
        <LoginForm /> ||
        <Redirect to={redirect} />
    )
}

export {
    Login
}