import React, {useState, useEffect, useContext} from 'react';
import AuthContext from 'auth/context';
import {API_URL} from 'config';
import {Heading} from 'components/general/heading';
import { alert } from 'alert';
import {DashCard} from 'components/card';

const Dashboard = ()=>{
    let [stats, setStats] = useState(false);
    let {auth, currentUser} = useContext(AuthContext);

    const getStats = async()=>{
        let stats = await auth.ajax(API_URL+'/v1/license/stats');
        if(stats.ok){
            setStats(stats.stats);
        }
    }

    useEffect(
        ()=>{
            getStats();
        },
        []
    )

    return <>
        <Heading text={'Welcome back, '+(currentUser.username)} />
        <DashCard title="Total licenses" value={stats.licenses} />
        <DashCard title="Total unclaimed licenses" value={stats.unclaimed} />
        <DashCard title="Total shops on hold" value={stats.on_hold} />
    </>
}


export {
    Dashboard
}