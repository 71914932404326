import React, {useState, useContext} from 'react';
import AuthContext from 'auth/context';
import './agents.css';
import {Button} from 'components/general/button';
import {BreadcrumbBack} from 'components/general/navigation';
import {Heading} from 'components/general/heading';
import {Form, TextInput} from 'components/general/form';
import {alert} from 'alert';
import {API_URL} from 'config';

const CreateAgent = ()=>{
    let {auth} = useContext(AuthContext);
    let [newAgent, setNewAgent] = useState({});
    let [saving, setSaving] = useState(false);

    const createAgent = async ()=>{
        setSaving(true);
        let newagent = await auth.ajax(API_URL+'/v1/agents/create', 
            {
                method: 'POST', 
                body: JSON.stringify(newAgent)
            }
        );
        setSaving(false);
        if(!newagent.ok){
            return alert.error(newagent.error);
        }
        alert.success("Agent created successfully!");
    }

    return <React.Fragment>
        <BreadcrumbBack href="/agents" text="Back to all agents" />         
            <React.Fragment>
                <Heading loading={saving} text='Create an agent' />
                <Form initial={newAgent} callback={setNewAgent}>
                    <TextInput autocomplete="off" label="username" objKey="username" />
                    <TextInput autocomplete="off" label="e-mail" objKey="email" />
                    <TextInput autocomplete="off" label="password" objKey="password" />
                    <Button inProgress={{state: saving, text: 'Creating an agent...'}} onClick={createAgent} type="primary">Create an agent</Button>
                </Form>
            </React.Fragment>    
    </React.Fragment>
}

export {
    CreateAgent
}