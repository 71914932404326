import React, {useState} from 'react';
import './login.css';
import {authService} from 'auth';

const LoginForm = ()=>{
    let [error, setError] = useState(false);

    const login = async (e)=>{
        e.preventDefault();
        setError(false);
        let button = document.getElementById('submit');
        button.disabled = "true";
        let username = document.getElementById('username').value.trim();
        let password = document.getElementById('password').value;
        let res = await authService.login({username, password});
        button.disabled = false;
        if(!res.ok){
            return setError(res.error)
        }
        setError(false);
    }
    
    return (
        <div className="login-form__holder">
            <form onSubmit={login} className="login-form">
                <h1 className="login__h1">Login</h1>
                <h2 className="color--secondary login__h2">sign in to continue</h2>
                {
                    error &&
                    <div className="form__error">
                        {error}
                    </div>
                }
                <div className="form__combo">
                    <span className="form__label">
                        Username/E-mail
                    </span>
                    <input id="username" className="form__input login__form__input" type="text" />
                </div>
                <div className="form__combo">
                    <span className="form__label">
                        Password
                    </span>
                    <input id="password" className="form__input login__form__input" type="password" />
                </div>
                <button id="submit" className="button button--primary login__form__button" type="submit">Login</button>
            </form>
            <img className="form__logo" src={process.env.PUBLIC_URL + '/logo--secondary.png'} />
        </div>
    )
}

export {
    LoginForm
}